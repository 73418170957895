<i18n>
{
  "en": {
    "browser_title": "Help",
    "heading": "Help",
    "table_contents": "Table of Contents"
  },
  "ja": {
    "browser_title": "ヘルプ",
    "heading": "ヘルプ",
    "table_contents": "目次"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <div id="faq" class="page-margin">
      <div class="block-header">
        <div class="container">
          <h1>{{ $t('heading') }}</h1>
        </div>
      </div>

      <div class="container">
        <!-- TOC (only display if there's more than 1 set of faq types) -->
        <div class="section" v-if="faqTypes.length > 1">
          <h3>{{ $t('table_contents') }}</h3>
          <ul>
            <li v-for="type in faqTypes" :key="type.id">
              <a
                :href="`#help-${type.id}`"
                v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }"
              >{{ type.name }}</a>
            </li>
          </ul>
        </div>

        <!-- FAQs -->
        <div v-for="type in faqTypes" :key="type.id">
          <div :id="`help-${type.id}`" class="section is-wide">
            <h2 class="is-primary">{{ type.name }}</h2>
            <div class="help-panel-group">
              <div class="help-panel" v-for="question in faqQuestions[type.id]" :key="question.id" :class="{ 'is-open' : locale == 'en' }">
                <div
                  class="panel-title panel-toggle"
                  @click="togglePanel($event)"
                >{{ question.question }}</div>
                <div class="panel-content" v-html="question.answer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
      faqTypes: [],
      faqQuestions: [],
    };
  },
  created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;

    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    this.refreshHTML();
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async refreshHTML() {
      const bfAPI = new BifrostAPI();

      const faqTypes = await bfAPI.getFaqTypes();
      this.faqTypes = faqTypes[this.locale];

      const faqs = await bfAPI.getFaqQuestions();
      this.faqQuestions = faqs[this.locale];
    },
    togglePanel(event) {
      const el = event.target.parentElement;
      el.classList.toggle('is-open');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_help.scss';
</style>
